// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analyse-js": () => import("./../../../src/pages/analyse.js" /* webpackChunkName: "component---src-pages-analyse-js" */),
  "component---src-pages-app-privacy-policy-js": () => import("./../../../src/pages/appPrivacyPolicy.js" /* webpackChunkName: "component---src-pages-app-privacy-policy-js" */),
  "component---src-pages-automate-js": () => import("./../../../src/pages/automate.js" /* webpackChunkName: "component---src-pages-automate-js" */),
  "component---src-pages-boosters-js": () => import("./../../../src/pages/boosters.js" /* webpackChunkName: "component---src-pages-boosters-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-demo-videos-js": () => import("./../../../src/pages/demoVideos.js" /* webpackChunkName: "component---src-pages-demo-videos-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-forward-js": () => import("./../../../src/pages/forward.js" /* webpackChunkName: "component---src-pages-forward-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-olaf-js": () => import("./../../../src/pages/olaf.js" /* webpackChunkName: "component---src-pages-olaf-js" */),
  "component---src-pages-organise-js": () => import("./../../../src/pages/organise.js" /* webpackChunkName: "component---src-pages-organise-js" */),
  "component---src-pages-personas-js": () => import("./../../../src/pages/personas.js" /* webpackChunkName: "component---src-pages-personas-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/termsAndConditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-timeline-js": () => import("./../../../src/pages/timeline.js" /* webpackChunkName: "component---src-pages-timeline-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/useCases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */)
}

